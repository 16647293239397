

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AppService { 
    baseUrl = "http://185.224.139.229:5002/api/v1/";    
    // baseUrl = "http://localhost:5002/api/v1/";    


  header = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'token': `Bearer ${localStorage.getItem('token')}`
    })
  }
  constructor(private http: HttpClient, private router: Router) { }

  authenticate(){
    if(!localStorage.getItem('token')){
      this.router.navigate(['/']);
    }
  }

  login(data: any): Observable<any> {
    let url = this.baseUrl.concat('admin/login');
    return this.http.post(url,data);
  }

  forgotPassword(data):  Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('forgot-password');
    return this.http.post(url,data);
  }

  getVendors(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('users/list');
    return this.http.post(url,data);
  }

  getDocument(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/document/list');
    return this.http.post(url,data);
  }

 

  getContact(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/contact/list');
    return this.http.post(url,data);
  }

  getOurproducts(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/ourproduct/list');
    return this.http.post(url,data);
  }

  addOurprodct(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/ourproduct/add');
    return this.http.post(url,data);
  }

  addBanner(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/banner/add');
    return this.http.post(url,data);
  }

  addDocument(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/document/add');
    
    let result = this.http.post(url,data);
    console.warn(result);
    return result;
  }

  getBanner(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/banner/list');
    return this.http.post(url,data);
  }

  deleteVendor(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('users/remove');
    return this.http.post(url,data);
  }

  deleteBanner(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/banner/remove');
    return this.http.post(url,data);
  }

  deleteOurproduct(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/ourproduct/remove');
    return this.http.post(url,data);
  }

  deleteDocument(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/document/remove');
    return this.http.post(url,data);
  }

  

   getUsers(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('user/list');
    return this.http.post(url,data);
  } 

  getDrivers(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/driver/list');
    return this.http.post(url,data);
  }

  getReports(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/report/all');
    return this.http.post(url,data);
  }


  blockUnblock(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/blockunblock');
    return this.http.post(url,data);
  }

  
  changeStatus(data):Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('user-status');
    return this.http.post(url,data);
  }

  deleteUser(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('users/remove');
    return this.http.post(url,data);
  }

  deleteDriver(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/driver/remove');
    return this.http.post(url,data);
  }

  changePassword(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('change-password');
    return this.http.post(url,data);
  }

  editProfile(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/resetpassword');
    return this.http.post(url,data);
  }

  getUser(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/detail');
    return this.http.post(url,data);
  }

  updateUser(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('/user/updateprofile');
    return this.http.post(url,data);
  }
  

  getServices(data):Observable<any> {
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/category/list');
    return this.http.post(url,data);
  }

  addService(data):Observable<any> {
    let url = this.baseUrl.concat('admin/category/add');
    return this.http.post(url,data);
  }

  viewVehicle(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/category/view');
    return this.http.post(url,data);
  }

  editVehicle(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/category/update');
    return this.http.post(url,data);
  }

 

  updateVehicle(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('service/update');
    return this.http.post(url,data);
  }

  dashboardCount(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat(`admin/dashboard`);
    return this.http.post(url,data);
  }


  deleteVehicle(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('service/remove');
    return this.http.post(url,data);
  }

  allSizes(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/ourproduct/list');
    return this.http.get(url,data);
  }

  addSize(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/size/add');
    return this.http.post(url,data);
  }

  allColor(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/color/list');
    return this.http.post(url,data);
  }

  addColor(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/color/add');
    return this.http.post(url,data);
  }

  deleteSize(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/size/remove');
    return this.http.post(url,data);
  }

  deleteColor(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('admin/color/remove');
    return this.http.post(url,data);
  }

  logout(data): Observable<any>{
    this.authenticate();
    data.token = `Bearer ${localStorage.getItem('token')}`;
    let url = this.baseUrl.concat('user/logout');
    return this.http.post(url,data);
  }

}
